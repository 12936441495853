@media screen and (max-width: 600px) {
  table thead {
    display: none;
  }
  table td {
    display: flex;
    border: none;
  }
  table tr {
    border-bottom: none;
  }
  .noBorder {
    border-bottom: none !important;
  }
  hr {
    width: 100%;
    height: 2px;
    color: black;
  }
  table td {
    content: attr(label);
    font-weight: bold;
    width: 300px;
    min-width: 220px;
  }
  .tbm {
    border-bottom: 1px solid rgb(87, 86, 86) !important;
  }
  .lbl {
    text-align: start;
    margin-right: 20px;
    font-weight: 600;
    display: block !important;
  }
  .alignCenter {
    margin-left: 15px;
  }
  .margl {
    margin-left: 10%;
  }
  .textW {
    width: 100%;
  }

  .table.gy-4 th,
  .table.gy-4 td {
    padding-left: 0rem;
  }
}
.table.table-row-bordered tr {
  border-bottom-width: 4px !important;
}

@media screen and (max-width: 2000px) {
  .lbl {
    display: none;
  }
  .table.table-row-bordered tr {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
    border-bottom-color: #f1f4f5;
  }
}
